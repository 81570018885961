<template>
  <div class="layout">
    <!-- <van-address-edit
      :area-list="areaList"
      show-delete
      show-set-default
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
      :address-info="{
        name: info.name,
        tel: info.tel,
        addressDetail: info.addressDetail,
        areaCode: info.areaCode,
        postalCode: info.postalCode,
        isDefault: info.isDefault,
      }"
    /> -->
    <div>
      <van-field
        v-model="parameter.nameAddress"
        label="收货联系人"
        label-width="140px"
        placeholder="请输入联系人"
      />
      <van-field
        v-model="parameter.phoneAddress"
        type="tel"
        label="收货联系人手机号"
        label-width="140px"
        placeholder="请输入联系人手机号"
        maxlength="11"
      />
      <van-field
        readonly
        clickable
        name="area"
        :value="value"
        label="地区选择"
        placeholder="选择省市区"
        @click="showArea = true"
        label-width="140px"
      />
      <van-field
        v-model="parameter.detailAddress"
        label="详细地址"
        label-width="140px"
        placeholder="请输入详细地址"
        class="detail_address"
      />
      <p class="tips">注：详细地址无需重复输入省市区，请输入小区号、门牌号等</p>

      <van-field
        name="switch"
        label="设置默认地址"
        input-align="right"
        v-if="!$route.query.isConfirmAnOrder"
      >
        <template #input>
          <van-switch
            v-model="parameter.defaultAddress"
            size="20"
            active-color="#009A4D"
          />
        </template>
      </van-field>
    </div>
    <div class="btn_bg">
      <button @click="onSave">{{ msg }}</button>
    </div>
    <van-popup v-model="showArea" position="bottom">
      <van-area
        :value="parameter.areaCode"
        :area-list="areaList"
        @confirm="onConfirm"
        @cancel="showArea = false"
      />
    </van-popup>
    <p class="delete" v-if="!isAdd" @click="onDelete">删除</p>
  </div>
</template>

<script>
var _ = require("lodash");
import { areaList } from "@vant/area-data";
import { Dialog } from "vant";

export default {
  name: "addAddress",

  data() {
    return {
      value: "",
      showArea: false,
      areaList,
      msg: "",
      isAdd: false,
      // detailAddress: "",
      parameter: {
        addressCode: "",
        city: "",
        consignee: "",
        country: "",
        county: "",
        defaultAddress: false,
        detailAddress: "",
        id: "",
        nameAddress: "",
        phoneAddress: "",
        province: "",
      },
    };
  },

  mounted() {},
  created() {
    if (sessionStorage.getItem("address")) {
      this.getAddress();
      if (this.$route.query.isConfirmAnOrder) {
        this.msg = "保存并使用新地址";
      } else {
        this.msg = "保存地址";
      }
    } else {
      this.isAdd = true;
      if (this.$route.query.isConfirmAnOrder) {
        this.msg = "新增并使用新地址";
      } else {
        this.msg = "新增地址";
      }
      if (Number(this.$route.query.isDefault)) {
        this.parameter.defaultAddress = true;
      }
    }
  },
  beforeDestroy() {
    sessionStorage.removeItem("address");
  },
  methods: {
    getAddress() {
      this.parameter = JSON.parse(sessionStorage.getItem("address"));
      // this.detailAddress = this.parameter.detailAddress;
      _.forEach(this.areaList.city_list, (o, c) => {
        if (o == this.parameter.city) {
          let cityId = String(_.take(c, 2));
          _.forEach(this.areaList.county_list, (i, a) => {
            if (i == this.parameter.county) {
              let districtId = String(_.take(a, 2));
              if (cityId == districtId) {
                this.parameter.areaCode = a;
              }
            }
          });
        }
      });
      this.value =
        this.parameter.province +
        "/" +
        this.parameter.city +
        "/" +
        this.parameter.county;
    },
    onConfirm(values) {
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      let a = this.value.split("/");
      this.parameter.province = a[0];
      if (a[0] != a[1]) this.parameter.city = a[1];
      this.parameter.county = a[2];
      this.showArea = false;
    },
    onDelete(val) {
      Dialog.confirm({
        title: "确定删除此地址？",
      })
        .then(async () => {
          const data = await this.$API.user.deleteAddress({
            object: {
              id: this.parameter.id,
            },
          });
          if (data.data.code != "SUCCESS") return false;
          this.Toast.success("删除成功");
          this.$router.go(-1);
        })
        .catch(() => {
          // on cancel
        });
    },
    async onSave(val) {
      if (!this.parameter.nameAddress) {
        this.Toast("请输入收货联系人");
      } else if (!this.parameter.phoneAddress) {
        this.Toast("请输入联系人手机号");
      } else if (!this.parameter.province) {
        this.Toast("请选择地区");
      } else if (!this.parameter.detailAddress) {
        this.Toast("请输入详细地址");
      } else {
        // if (this.parameter.detailAddress !== this.detailAddress) {
        //   this.parameter.detailAddress =
        //     this.parameter.province +
        //     this.parameter.city +
        //     this.parameter.county +
        //     this.detailAddress;
        // }

        if (this.isAdd) {
          const data = await this.$API.user.insertAddress({
            object: this.parameter,
          });
          if (data.data.code != "SUCCESS") return false;
          this.Toast.success("新增地址成功");
          if (this.$route.query.isConfirmAnOrder) {
            this.$router.go(-2);
          } else {
            this.$router.go(-1);
          }
        } else {
          if (this.$route.query.isConfirmAnOrder)
            this.parameter.defaultAddress = true;
          const data = await this.$API.user.updateAddress({
            object: this.parameter,
          });
          if (data.data.code != "SUCCESS") return false;
          this.Toast.success("保存地址成功");
          if (this.$route.query.isConfirmAnOrder) {
            this.$router.go(-2);
          } else {
            this.$router.go(-1);
          }
        }
      }
    },
    // addressFocus() {
    //   this.detailAddress = "";
    // },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  background: #f5f5f5;
  position: relative;
  top: 46px;
  .delete {
    position: absolute;
    top: -33px;
    right: 14px;
    z-index: 9999;
    font-size: 16px;
    line-height: 20px;
  }
  .detail_address {
    padding-bottom: 0;
  }
  .detail_address::after {
    display: none;
  }
  .tips {
    background: #fff;
    position: relative;
    padding: 10px 16px;
    color: red;
    font-size: 12px;
  }
  .tips::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 0.16rem;
    bottom: 0;
    left: 0.16rem;
    border-bottom: 0.01rem solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}
button {
  width: 80%;
  border: none;
  outline: none;
  padding: 12px 0;
  margin: 4px 0;
  color: #fff;
  background: #009a4d;
  border-radius: 20px;
}
.btn_bg {
  position: fixed;
  bottom: 0;
  background: #fff;
  left: 0;
  right: 0;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}
.van-icon {
  margin-right: 10px;
}
/deep/.van-address-edit {
  padding: 0;
}
/deep/.van-address-edit__fields {
  border-radius: 0;
}
/deep/.van-address-edit__default {
  border-radius: 0;
}
/deep/.van-button--danger {
  background: #009a4d;
  border: 1px solid #009a4d;
}
/deep/.van-switch--on {
  background: #009a4d;
}
// /deep/.van-address-edit__fields .van-cell:last-child{
//   display: none;
// }
</style>
